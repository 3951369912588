import { generateElements } from '@/utils.ts';

const MODAL_MARKUP = `
    <div class="modal fade">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <span class="close" data-dismiss="modal">x</span>
                    <nav class="sidebar"></nav>
                </div>
            </div>
        </div>
    </div>`;

/**
 * Inject a BS3 modal into the document, with the given class and HTML content
 *
 * @param navClass
 * @param content
 */
function injectModal(content: string, navClass?: string) {
    const modal = generateElements(MODAL_MARKUP).item(0) as HTMLElement;

    // Set the content of the modal's body <nav>
    //
    const nav = modal.querySelector('nav') as HTMLElement;
    nav.innerHTML = content;

    // Set the class if specified
    if (navClass) {
        nav.classList.add(navClass);
    }

    const body = document.querySelector('body') as HTMLBodyElement;
    body.append(modal);

    // Get a Boostrap Modal object to manage things
    const bsModal = $(modal);

    // Setup so that clicking on an internal anchor link (starts with '#')
    // manually hides the modal
    const internalAnchors = modal.querySelectorAll<HTMLAnchorElement>('a[href^="#"]');
    Array.from(internalAnchors)
        .forEach(
            (a) => a.addEventListener(
                'click',
                () => {
                    bsModal.modal('hide');
                }
            )
        );

    // Setup a one-shot event handler to close the modal on window resizes.
    window.addEventListener('resize', () => {
        bsModal.modal('hide');
    });

    modal.addEventListener('hidden.bs.modal', () => {
        modal.remove();
    });

    modal.addEventListener('shown.bs.modal', () => {
        // Focus on the first link, so that keyboard ESC works
        const first = modal.querySelector('a');
        if (first) {
            first.focus();
        }
    });

    bsModal.modal('show');
}


/**
 * Setup a button with the id 'crh-toggle-leftnav' to open up a modal
 * showing a copy of the leftnav.  Used at mobile sizes
 */
function crhToggleLeftNav() {
    const button = document.getElementById('crh-toggle-leftnav');

    if (button) {
        const leftnav = document.querySelector<HTMLElement>('nav.sidebar');

        if (!leftnav) {
            // no leftnav, no point in having this button.
            button.remove();
            return;
        }

        // Copy the heading of the nav to be the text of the button
        const label = leftnav.querySelector<HTMLElement>('.left-nav-header');
        if (label) {
            button.textContent = label.textContent;
        }

        button.addEventListener('click', () => {
            injectModal(leftnav.innerHTML, 'sidebar-left');
        });
    }
}

// Setup button that triggers a popup leftnav on small width displays
crhToggleLeftNav();
