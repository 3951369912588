/**
 * Generate HTML elements from HTML text
 * See: https://youmightnotneedjquery.com/#create_elements
 *
 * @param html
 */
export function generateElements(html: string) {
    const template = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content.children;
}
