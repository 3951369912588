/**
 * Add keyboard behaviors to megamenu
 *
 */
import { hideMenu, showMenu } from '@/megamenu';

type KeyHandlerMap = Record<string, (menu_li: HTMLLIElement) => void>;

/**
 * Handle up/down movement of the main menu.  Basically opens
 * the submenu and focuses on the first or last item
 *
 * @param menu_li
 * @param submenu_li
 */
function mainmenu_up_down(
    menu_li: HTMLLIElement,
    submenu_li: HTMLLIElement,
) {
    // open submenu if not already open
    //
    if (menu_li.firstElementChild?.getAttribute('aria-expanded') !== 'true') {
        showMenu(menu_li);
    }

    // For the given submenu LI we've selected, focus on its A child
    //
    if (submenu_li.firstElementChild instanceof HTMLAnchorElement) {
        submenu_li.firstElementChild.focus();
    }
}


/**
 * Open the submenu and focus on the last item there.
 *
 */
function mainmenu_up(menu_li: HTMLLIElement) {
    const submenu = menu_li.firstElementChild?.nextElementSibling?.lastElementChild;

    if (submenu instanceof HTMLLIElement) {
        mainmenu_up_down(menu_li, submenu);
    }
}


/**
 * Open the submenu and focus on the first item there.
 *
 */
function mainmenu_down(menu_li: HTMLLIElement) {
    const submenu = menu_li.firstElementChild?.nextElementSibling?.firstElementChild;

    if (submenu instanceof HTMLLIElement) {
        mainmenu_up_down(menu_li, submenu);
    }
}


/**
 * Handle left/right movement of the main menu
 *
 * @param menu_li The current LI
 * @param adjacent_li The LI we're moving to
 * @param from_submenu Was this change triggered by a left/right key on a submenu
 */
function mainmenu_left_right(
    menu_li: HTMLLIElement,
    adjacent_li: HTMLLIElement,
    from_submenu: boolean = false
) {
    // close submenu if open
    if (menu_li.firstElementChild?.getAttribute('aria-expanded') === 'true') {
        hideMenu(menu_li);
    }

    if (from_submenu && adjacent_li.classList.contains('dropdown')) {
        mainmenu_down(adjacent_li);
    } else {
        const a = adjacent_li.firstElementChild;
        if (a instanceof HTMLAnchorElement) {
            a.focus();
        }
    }
}


/**
 * Move Left on the main menu, or wrap around the the last item.
 *
 * @param menu_li
 * @param from_submenu
 */
function mainmenu_left(
    menu_li: HTMLLIElement,
    from_submenu: boolean = false
) {
    const adjacent_li = menu_li.previousElementSibling
        || menu_li.parentElement?.lastElementChild;

    mainmenu_left_right(menu_li, adjacent_li as HTMLLIElement, from_submenu);
}


/**
 * Move Right on the main menu, or wrap around the the first item.
 *
 * @param menu_li
 * @param from_submenu
 */
function mainmenu_right(
    menu_li: HTMLLIElement,
    from_submenu: boolean = false
) {
    const adjacent_li = menu_li.nextElementSibling
        || menu_li.parentElement?.firstElementChild;

    mainmenu_left_right(menu_li, adjacent_li as HTMLLIElement, from_submenu);
}


/**
 * Handle up/down moves on the submenu, of we're at the top or bottom
 * of the submenu, switch to the parent main menu
 *
 */
function submenu_up_down(menu_li: HTMLLIElement, adjacent_li: Element | null) {
    const target_li = adjacent_li || menu_li.parentElement?.parentElement;

    if (target_li?.firstElementChild instanceof HTMLAnchorElement) {
        target_li.firstElementChild.focus();
    }
}


/**
 * Move up on the submenu, jumping up to the main menu if necessary
 *
 * @param menu_li
 */
function submenu_up(menu_li: HTMLLIElement) {
    submenu_up_down(menu_li, menu_li.previousElementSibling);
}


/**
 * Move down on the submenu, wrapping to the main menu if necessary
 *
 * @param menu_li
 */
function submenu_down(menu_li: HTMLLIElement) {
    submenu_up_down(menu_li, menu_li.nextElementSibling);
}


/**
 * ...closes the submenu, moves focus to the previous item in the menubar, and,
 * if focus is now on a menuitem with a submenu, either opens the submenu of that
 * menuitem without moving focus into the submenu, or opens the submenu of that
 * menuitem and places focus on the first item in the submenu.
 */
function submenu_left(menu_li: HTMLLIElement) {
    const main_li = menu_li.parentElement?.parentElement;

    if (main_li instanceof HTMLLIElement) {
        mainmenu_left(main_li, true);
    }
}


/**
 * ... closes the submenu and any parent menus, moves focus to the next item in the menubar,
 * and, if focus is now on a menuitem with a submenu, either opens the submenu of that
 * menuitem without moving focus into the submenu, or opens the submenu of that menuitem
 * and places focus on the first item in the submenu.
 */
function submenu_right(menu_li: HTMLLIElement) {
    const main_li = menu_li.parentElement?.parentElement;

    if (main_li instanceof HTMLLIElement) {
        mainmenu_right(main_li, true);
    }
}


/**
 * Handle the ESC key being pressed while on a submenu, by closing
 * the dropdown and focusing on the main menu item at the top.
 *
 * @param menu_li
 */
function submenu_esc(menu_li: HTMLLIElement) {
    // close menu
    const main_li = menu_li.parentElement?.parentElement as HTMLElement;
    hideMenu(main_li);

    // move to mainmenu parent
    (main_li.firstElementChild as HTMLAnchorElement).focus();
}


/**
 * Map of main menu KeyboardEvent keys to handler functions
 */
const mainMenuHandlers: KeyHandlerMap = {
    ArrowUp: mainmenu_up,
    ArrowDown: mainmenu_down,
    ArrowLeft: mainmenu_left,
    ArrowRight: mainmenu_right,
};


/**
 * Map of submenu KeyboardEvent keys to handler functions
 */
const subMenuHandlers: KeyHandlerMap = {
    ArrowUp: submenu_up,
    ArrowDown: submenu_down,
    ArrowLeft: submenu_left,
    ArrowRight: submenu_right,
    Escape: submenu_esc,
};


/**
 * Add a keyboard event listener to handle navigation around the megamenu.
 *
 */
function setup() {
    const megamenu = document.getElementById('main-menu');

    if (!megamenu) {
        return;
    }

    megamenu.addEventListener('keydown', (event: Event) => {
        const evt = event as KeyboardEvent;

        if (evt.target instanceof HTMLAnchorElement) {
            // Figure out whether this keydown should be handled for main menu
            // items or for subment items
            //
            const handlers = evt.target.parentElement?.parentElement?.classList.contains('dropdown-menu')
                ? subMenuHandlers
                : mainMenuHandlers;

            const handler = handlers[evt.key];

            // If we're setup to handle this particular key, then stop
            // other processing and dispatch to one of our functions.
            //
            if (handler && (evt.target.parentElement instanceof HTMLLIElement)) {
                evt.preventDefault();
                evt.stopPropagation();

                // call the handler passing the LI which wraps the A tag
                // that the keyboard was focused on
                handler(evt.target.parentElement);
            }
        }
    });
}

setup();
